import { Options, Vue } from "vue-class-component";
import API from "@/api/auth";
import { useToast } from "vue-toastification";
import BackgroundImages from "@/components/background-images/background-images.vue";

@Options({
    name: "app-login",
    components: {
        "app-background-images": BackgroundImages,
    },
})
export default class loginModPass extends Vue {
    public isAuthLoading = false;
    private toast = useToast();

    public password = "";
    public passwordNew = "";
    public passwordConfirm = "";

    private async loginModPass(): Promise<void> {
        if (this.$tools.isEmpty(this.password)) {
            this.toast.error("密码不能为空！");
            return Promise.reject(false);
        }
        if (this.$tools.isEmpty(this.passwordNew)) {
            this.toast.error("新密码不能为空！");
            return Promise.reject(false);
        }
        if (this.$tools.isEmpty(this.passwordConfirm)) {
            this.toast.error("确认密码不能为空！");
            return Promise.reject(false);
        }
        if (this.passwordNew !== this.passwordConfirm) {
            this.toast.error("确认密码不正确！");
            return Promise.reject(false);
        }
        if (this.userIsOne !== 1) {
            this.toast.error("非首次登入！");
            return Promise.reject(false);
        }
        this.isAuthLoading = true;
        await API.loginModPass({
            password: this.password,
            passwordNew: this.passwordNew,
            passwordConfirm: this.passwordConfirm,
        })
            .then((res: any) => {
                this.toast.success(res.msg);
                this.$router.replace("/");
            })
            .finally(() => {
                this.isAuthLoading = false;
            });
    }

    get siteTitle() {
        return this.$store.getters["ui/siteTitle"];
    }

    get userInfo() {
        return this.$store.getters["auth/userInfo"];
    }

    get userIsOne() {
        return this.userInfo.userIsOne;
    }

    get username() {
        return this.userInfo.userName;
    }
}
